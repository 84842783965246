import styled from 'styled-components'
import {ChangeEvent, useCallback, useState} from 'react'
import {FormikContextType, FormikValues, useFormikContext} from 'formik'

interface TextareaProps {
  maxLength: number
  name: string
}

function Textarea({maxLength, name}: TextareaProps) {
  const [charactersLength, setCharactersLength] = useState(0)
  const {setFieldValue}: FormikContextType<FormikValues> = useFormikContext()

  const handleOnChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    setCharactersLength(value.length)
    setFieldValue(name, value)
  }, [])

  return (
    <Wrapper>
      <StyledTextarea maxLength={maxLength} onChange={handleOnChange} />
      <Count>
        ({charactersLength}/{maxLength})
      </Count>
    </Wrapper>
  )
}

export default Textarea

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
  box-sizing: border-box;
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 4px;
  padding: 16px;
`

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 78px;

  border: none;

  resize: none;
  outline-style: none;

  font-weight: 500;
  font-size: 13px;
`

const Count = styled.span`
  position: absolute;
  bottom: 16px;
  right: 16px;

  color: #999999;
  font-size: 13px;
`
